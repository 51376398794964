import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import LogoComponent from "../../components/Logo";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  LinearProgress,
  InputLabel,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import ImageUploader from "../../components/multiUpload";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import { useParams } from "react-router-dom";
import { Api, ApiUpload } from "../../services/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ImageUnit {
  id: string;
  image: string;
}
interface groupsField {
  id: string;
  name: string;
}

const UserEdit: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [load, setLoad] = useState(false);
  const [groups, setGroups] = useState<groupsField[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [imageUnit, setImageUnit] = useState<ImageUnit[]>([]);
  const [store, setStore] = useState<string>("");
  const [stores, setStores] = useState<groupsField[]>([]);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordOne, setPasswordOne] = useState<string>("");
  const [passwordTwo, setPasswordTwo] = useState<string>("");
  const [toggle, setToogle] = useState(false);
  const [errorPassword, seErrorPassword] = useState(false);
  const { id } = useParams();

  const token = getUserLocalStorage()?.token;
  const navigate = useNavigate();

  const handleImagesUploaded = (uploadedImages: File[]) => {
    setImages(uploadedImages);
  };

  const handleImagesDeleted = async (deletedImage: string) => {
    const responseUpload = await ApiUpload.delete(
      "deletesingle/users/" + deletedImage,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
        },
      }
    );

    if (responseUpload) {
      await Api.put(
        "users/" + deletedImage,
        {
          image: null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImageUnit([]);
    }
  };

  const displayCategory = useCallback(async () => {
    try {
      const response = await Api.get(`users/myuser/` + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      getGroupAndStore();
      setFirstName(response.data?.firstName);
      setLastName(response.data?.lastName);
      setEmail(response.data?.email);
      setGroup(response.data?.groupId);
      setStore(response.data?.storeId);
      if (response.data?.image && id) {
        const pic =
          "https://upload.megaroga.dev.br/storage/" + response.data?.image;
        setImageUnit([...imageUnit, { id: id, image: pic }]);
      }
      setChecked(response.data?.status);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, token]);

  useEffect(() => {
    if (id !== "add") {
      setFirstName("");
      setLastName("");
      displayCategory();
    } else {
      getGroupAndStore();
    }
  }, [id, displayCategory]);

  const handleChangeStatus = () => {
    setChecked(!checked);
  };

  const handleSave = async () => {
    setLoad(true);
    if (id === "add") {
      handleCreate();
    } else {
      handleEdit(id);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await Api.post(
        "users",
        {
          firstName: firstName,
          lastName: lastName,
          status: checked,
          email: email,
          password: password,
          group_id: group,
          store_id: store,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (images[0]) {
        const formData = new FormData();
        formData.append("image", images[0]);
        formData.append("table", "users");
        formData.append("table_id", response.data.id);

        const responseUpload = await ApiUpload.post("uploadsingle", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
          },
        });

        await Api.put(
          "users/" + response.data.id,
          {
            image: responseUpload.data.path,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      navigate("/usuarios/" + response.data.id);
      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleEdit = async (idUser: string | any) => {
    try {
      await Api.put(
        "users/" + idUser,
        {
          firstName: firstName,
          lastName: lastName,
          group_id: group,
          status: checked,
          store_id: store,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (images[0]) {
        const formData = new FormData();
        formData.append("image", images[0]);
        formData.append("table", "users");
        formData.append("table_id", idUser);

        const responseUpload = await ApiUpload.post("uploadsingle", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
          },
        });

        await Api.put(
          "users/" + idUser,
          {
            image: responseUpload.data.path,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setGroup(event.target.value);
  };

  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };

  const handleClickOpen = () => {
    setToogle(!toggle);
  };

  const handleChangePasswordOne = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordOne(event.target.value);
  };

  const handleChangePasswordTwo = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordTwo(event.target.value);
  };

  const handleClickPasswordChange = async () => {
    if (passwordOne === passwordTwo) {
      await Api.put(
        "users/" + id,
        {
          password: passwordOne,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setToogle(!toggle);
      setPasswordOne("");
      setPasswordTwo("");
    } else {
      seErrorPassword(!errorPassword);
      setTimeout(() => seErrorPassword(false), 5000);
    }
  };

  const getGroupAndStore = async () => {
    const allGroups = await Api.get(`group/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    const allStores = await Api.get(`store/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    setGroups(allGroups.data);
    setStores(allStores.data);
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      {errorPassword && (
        <DivAlert>
          <Alert variant="filled" severity="error">
            As senhas não coincidem, tente novamente por favor.
          </Alert>
        </DivAlert>
      )}
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        {load && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ p: 4, m: 4, background: "#ffffff" }}>
          <Grid container>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%", mr: 2, my: 2 }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Nomes"
                  variant="standard"
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Sobrenomes"
                  variant="standard"
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%", mr: 2, my: 2 }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                {id === "add" ? (
                  <TextField
                    sx={{ my: 2 }}
                    id="standard-basic"
                    label="Senha"
                    variant="standard"
                    value={password}
                    onChange={handleChangePassword}
                  />
                ) : (
                  <Button variant="outlined" onClick={handleClickOpen}>
                    Alterar senha
                  </Button>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl
                variant="standard"
                sx={{ width: "96%", mr: 2, my: 2 }}
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Grupo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={group}
                  label="Grupo"
                  onChange={handleChangeSelect}
                >
                  {groups.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl variant="standard" sx={{ width: "96%", m: 2 }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Store
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={store}
                  label="Sub categoria"
                  onChange={handleChangeStore}
                >
                  {stores.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8} sm={8} md={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChangeStatus}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Status"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <FormControl sx={{ my: 2, width: "100%" }}>
                <ImageUploader
                  limit={1}
                  onImagesUploaded={handleImagesUploaded}
                  onImagesDeleted={handleImagesDeleted}
                  imagePreview={imageUnit}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ my: 2 }}>
            <Button onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </FormControl>
          <Dialog
            open={toggle}
            onClose={handleClickOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Alterar senha"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid item xs={12}>
                  <FormControl sx={{ width: "96%", m: 1 }}>
                    <TextField
                      id="standard-basic"
                      label="Senha"
                      variant="standard"
                      value={passwordOne}
                      onChange={handleChangePasswordOne}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "96%", m: 1 }}>
                    <TextField
                      id="standard-basic"
                      label="Repetir senha"
                      variant="standard"
                      value={passwordTwo}
                      onChange={handleChangePasswordTwo}
                    />
                  </FormControl>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen}>Fechar</Button>
              <Button
                variant="contained"
                onClick={handleClickPasswordChange}
                autoFocus
              >
                Alterar senha
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserEdit;

const DivAlert = styled.div`
  position: fixed;
  top: 2.5em;
  right: 2.5em;
  z-index: 9999;
`;
