import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  LinearProgress,
  Button,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import { useParams } from "react-router-dom";
import { Api, ApiUpload } from "../../services/api";
import { useNavigate } from "react-router-dom";
import MultiUpload from "../../components/multiUploadProduct";
import LogoComponent from "../../components/Logo";
import { de } from "date-fns/locale";

interface ImageUnit {
  id: string;
  image: string;
}

interface sectorsField {
  id: string;
  name: string;
}

interface deleteImage {
  id: number;
  storeId: number;
  upload_id: number;
  image: string;
}

const Home = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [load, setLoad] = useState(false);
  const [name, setName] = useState<string>("");
  const [name_fantasy, setNameFantasy] = useState<string>("");
  const [whatsapp, setWhatsapp] = useState<string>("");
  const [method_pay, setMethodPay] = useState<string>("");
  const [division_pay, setDivisionPay] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [status, setChecked] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [imageUnit, setImageUnit] = useState<ImageUnit[]>([]);
  const [imgfeature, setImgFeature] = useState<string>("");
  const { id } = useParams();

  const token = getUserLocalStorage()?.token;
  const navigate = useNavigate();

  const handleImagesUploaded = (uploadedImages: File[]) => {
    setImages(uploadedImages);
  };

  const handleImagesDeleted = async (deletedImage: any) => {
    const responseUpload = await ApiUpload.delete(
      "/uploadfile/delete/" + deletedImage.upload_id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
        },
      }
    );

    if (responseUpload) {
      await Api.put('store/' + deletedImage.storeId, {
        image: null,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await Api.delete("storeImages/" + deletedImage.id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newItem = imageUnit.filter((item) => item.id !== deletedImage.id);
      setImageUnit(newItem);
    }
  };

  const handleImagesMark = async (image: string) => {
    await Api.put(
      "store/" + id,
      {
        image: image,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setImgFeature(image);
  };

  const displayStore = useCallback(async () => {
    try {
      const response = await Api.get(`store/` + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });

      if (response.data) {
        const allImages = await Api.get(
          `/storeImages/imagestore/` + response.data.id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          }
        );
        setImageUnit(allImages.data);
      }

      setName(response.data?.name);
      setNameFantasy(response.data?.name_fantasy);
      setWhatsapp(response.data?.whatsapp);
      setMethodPay(response.data?.method_pay);
      setDivisionPay(response.data?.division_pay);
      setCnpj(response.data?.cnpj);
      setChecked(response.data?.status);
      setImgFeature(response.data?.image);
      const blocksFromHTML = convertFromHTML(response.data?.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newEditorState = EditorState.createWithContent(state);
      setEditorState(newEditorState);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, token, setImageUnit]);

  useEffect(() => {
    if (id !== "add") {
      displayStore();
    }
  }, [id, displayStore]);

  const handleSave = async () => {
    setLoad(true);
    if (id === "add") {
      handleCreate();
    } else {
      handleEdit(id);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await Api.post(
        "store",
        {
          name: name,
          name_fantasy: name_fantasy,
          whatsapp: whatsapp,
          method_pay: method_pay,
          division_pay: division_pay,
          cnpj: cnpj,
          status: status,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (images.length) {
        images.forEach(async (image) => {
          const storeMulti = await Api.post(
            "/storeImages/",
            {
              store_id: response.data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const formData = new FormData();
          formData.append("table", "store");
          formData.append("table_id", storeMulti.data.id);
          formData.append(`image`, image);
          const responseUpload = await ApiUpload.post(
            "uploadsinglemulti",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
              },
            }
          );

          await Api.put(
            "/storeImages/" + storeMulti.data.id,
            {
              image: responseUpload.data.path,
              upload_id: responseUpload.data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        });
      }

      navigate("/lojas/" + response.data.id);
      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleEdit = async (idProduct: string | any) => {
    try {
      await Api.put(
        "store/" + idProduct,
        {
          name: name,
          name_fantasy: name_fantasy,
          whatsapp: whatsapp,
          method_pay: method_pay,
          division_pay: division_pay,
          cnpj: cnpj,
          status: status,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (images.length) {
        images.forEach(async (image) => {
          const storeMulti = await Api.post(
            "/storeImages/",
            {
              store_id: idProduct,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const formData = new FormData();
          formData.append("table", "store");
          formData.append("table_id", storeMulti.data.id);
          formData.append(`image`, image);
          const responseUpload = await ApiUpload.post(
            "uploadsinglemulti",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
              },
            }
          );

          await Api.put(
            "/storeImages/" + storeMulti.data.id,
            {
              image: responseUpload.data.path,
              upload_id: responseUpload.data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        });
      }
      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeNameFantasy = (event: ChangeEvent<HTMLInputElement>) => {
    setNameFantasy(event.target.value);
  };

  const handleChangeCnpj = (event: ChangeEvent<HTMLInputElement>) => {
    setCnpj(event.target.value);
  };

  const handleChangeWhatsapp = (event: ChangeEvent<HTMLInputElement>) => {
    setWhatsapp(event.target.value);
  };

  const handleChangeFormasPagamento = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setMethodPay(event.target.value);
  };

  const handleChangeJuros = (event: ChangeEvent<HTMLInputElement>) => {
    setDivisionPay(event.target.value);
  };

  const handleChangeStatus = () => {
    setChecked(!status);
  };

  const updateAllImages = () => {
    setChecked(!status);
  };

  // const onEditorStateChange = (newEditorState: EditorState) => {
  //   setEditorState(newEditorState);
  //   const contentState: ContentState = newEditorState.getCurrentContent();
  //   const rawContentState = convertToRaw(contentState);

  //   const contentStateFromRaw = convertFromRaw(rawContentState);
  //   const blocksHTML = stateToHTML(contentStateFromRaw);

  //   setDescription(blocksHTML);
  // };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        {load && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ p: 4, m: 4, background: "#ffffff" }}>
          <Grid container>
            {/* <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl variant="standard" sx={{ width: '96%', m:2 }}>
                <InputLabel id="demo-simple-select-filled-label">Setor</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sector}
                  label="Setor"
                  onChange={handleChangeSelect}
                  >
                  {sectors.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Nome da empresa"
                  variant="standard"
                  value={name}
                  onChange={handleChangeName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%" }}>
                <TextField
                  sx={{ m: 2 }}
                  id="standard-basic"
                  label="Nome de Fantasy"
                  variant="standard"
                  value={name_fantasy}
                  onChange={handleChangeNameFantasy}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Cnpj"
                  variant="standard"
                  value={cnpj}
                  onChange={handleChangeCnpj}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Whatsapp (Somente numérico, inicio 55)"
                  variant="standard"
                  value={whatsapp}
                  onChange={handleChangeWhatsapp}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Formas de pagamento"
                  variant="standard"
                  value={method_pay}
                  onChange={handleChangeFormasPagamento}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Juros"
                  variant="standard"
                  value={division_pay}
                  onChange={handleChangeJuros}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ m: 2 }}
                    checked={status}
                    onChange={handleChangeStatus}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Destaque"
              />
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <h5>Para a logo, imagem quadrada, minimo 200x200 pixels</h5>
                <FormControl sx={{ my: 2, width: "100%" }}>
                  <MultiUpload
                    limit={8}
                    onImagesUploaded={handleImagesUploaded}
                    onImagesDeleted={handleImagesDeleted}
                    onImagesMark={handleImagesMark}
                    imagePreview={imageUnit}
                    imageFeature={imgfeature}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </FormControl>
            </Grid> */}
          </Grid>
          <FormControl sx={{ my: 2 }}>
            <Button onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
