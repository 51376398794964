import { Alert, IconButton } from '@mui/material';
import { Trash2, Camera } from 'lucide-react';
import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components'

interface ImageUnit {
  id: string;
  image: string;
}

interface deleteImage {
  id: number;
  storeId: number;
  upload_id: number;
  image: string;
}

interface ImageUploaderProps {
  limit: number;
  imagePreview?: ImageUnit[];
  imageFeature: string;
  onImagesUploaded: (images: File[]) => void;
  onImagesDeleted: (texto: object) => void;
  onImagesMark: (texto: string) => void;
}

function ImageUploader({ limit, imagePreview, imageFeature, onImagesUploaded, onImagesDeleted, onImagesMark }: ImageUploaderProps) {
  
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [receivedImages, setReceivedImages] = useState<ImageUnit[]>([]);
  const [base64Strings, setBase64Strings] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  
  useEffect(() => {
    if (imagePreview !== undefined) {
      setReceivedImages(imagePreview);
    }
  }, [imagePreview]);

  const isImageValid = (file: File) => {
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    return acceptedTypes.includes(file.type);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const invalidFiles = acceptedFiles.filter(file => !isImageValid(file));

    if (invalidFiles.length > 0) {
      const invalidExtensions = invalidFiles.map(file => file.name.split('.').pop()).join(', ');
      setError(`As extensões de arquivo (${invalidExtensions}) não são válidas. Apenas jpg, jpeg e png são permitidos.`);
      setTimeout(() => setError(null), 5000);
      return;
    }
    
    if (base64Strings?.length + uploadedImages?.length + acceptedFiles.length + (imagePreview !== undefined ? imagePreview?.length : 0) <= limit) {
      const newImages = [...uploadedImages, ...acceptedFiles];
      
      const base64Array: any[] = [];

    for (let i = 0; i < acceptedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        base64Array.push(base64);
        if (base64Array.length === acceptedFiles.length) {
          setBase64Strings(base64Array);
        }
      };
      reader.readAsDataURL(acceptedFiles[i]);
    }
      
      setUploadedImages(newImages);
      onImagesUploaded(newImages);
    } else {
      setError(`Você atingiu o limite de ${limit} imagens.`);
      setTimeout(() => setError(null), 3000);
    }
  }, [uploadedImages, base64Strings, limit, onImagesUploaded]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeImage = (index: number) => {
    const updatedImages = [...uploadedImages];
    const updatedBase64 = [...base64Strings];
    updatedImages.splice(index, 1);
    updatedBase64.splice(index, 1);
    setUploadedImages(updatedImages);
    setBase64Strings(updatedBase64);
    onImagesUploaded(updatedImages);
  };

  const removeImageServer = (image: object) => {
    onImagesDeleted(image);
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const markImageMain = (code: string) => {
    onImagesMark(code);
  };
  
  return (
    <div>
      <DivAreaDragDrop {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Arraste e solte algumas imagens aqui, ou clique para selecionar imagens</p>
      </DivAreaDragDrop>
      {error && (
        <DivAlert>
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </DivAlert>
      )}
      { (uploadedImages?.length > 0 || receivedImages?.length > 0 || base64Strings?.length > 0) && (
        <div>
          <h2>Imagens Carregadas:</h2>
          <h5>Clique na imagem para destacar</h5>
          <ListUl>
            {receivedImages.map((file, index) => (
              file &&
              <div 
                className={`${
                  imageFeature === file.image ? 'borderBlue' : ''
                }`}
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <a onClick={() => markImageMain(file.image)} className="cursorPointer">
                  <img src={"https://upload.megaroga.dev.br/storage/"+file.image} width={135} height={135} title="Marcar como principal" alt="Images"/>
                </a>
                {hoveredIndex === index && (
                  <span>
                    <IconButton onClick={() => removeImageServer(file)}>
                      <Trash2 />
                    </IconButton>
                  </span>
                )}
              </div>
            ))}
            {base64Strings.map((file, index) => (
              file &&
              <div 
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={file} width={135} height={135} alt="Images"/>
                {hoveredIndex === index && (
                  <span>
                    <IconButton onClick={() => removeImage(index)}>
                      <Trash2 />
                    </IconButton>
                  </span>
                )}
              </div>
            ))}
          </ListUl>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;

const DivAreaDragDrop = styled.div`
  width: 100%;
  padding: 2em;
  border: 1px dashed #ddd;
  border-radius: 6px;
`;

const ListUl = styled.ul`
  width: 100%;
  display: flex;
  padding: 0;
  & div {
    width: 150px;
    height: 150px;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px dashed #282828;
    border-radius: 6px;
    position: relative;
    margin-right: 15px;
  }
  & span {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 9999;
    background-color: #fcc;
    display: inline-table;
    border-radius: 50%;
  }
`;

const DivAlert = styled.div`
  position: fixed;
  top: 2.5em;
  right: 2.5em;
  z-index: 9999;
`;