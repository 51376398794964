import { Api, ApiUpload } from "../../services/api";
import { iUser } from "./types";

export function setUserLocalStorage (user: iUser | null){
    localStorage.setItem("u", JSON.stringify(user));
}

export function getUserLocalStorage(){
    const json = localStorage.getItem('u');
    if(!json){
        return null;
    }
    const user = JSON.parse(json);

    return user ?? null;
}

export async function LoginRequest (email:string, password:string){
    const request = await Api.post('users/login', { username: email, password: password });
    console.log(request);
    if (request) { 
        return request.data;
    } else {
        return false;
    }
}

export async function LoginUpload (){

    const formData = new FormData();

    if (process.env.REACT_APP_PASS_UPLOAD && process.env.REACT_APP_USER_UPLOAD) {
        formData.append('email', process.env.REACT_APP_USER_UPLOAD);
        formData.append('password', process.env.REACT_APP_PASS_UPLOAD);
        const request = await ApiUpload.post('login', formData);
        if (request) {   
            return request.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
    
}