import React, { createContext, useEffect, useState } from "react";
import { IAuthProvider, IContext, iUser } from "./types";
import { getUserLocalStorage, LoginRequest, LoginUpload, setUserLocalStorage } from "./util";

export const AuthContext = createContext<IContext>({} as IContext)

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<iUser | null>()

    useEffect(()=> {
        const user = getUserLocalStorage();
        if(user){
            setUser(user);
        }
    },[]);

    async function authenticate(email: string, password: string) {
        const response = await LoginRequest(email, password);
        const login = await LoginUpload();
        
        if(response){
            const payload = {token: response.access_token, email };
            setUser(payload);
            setUserLocalStorage(payload);
            localStorage.setItem("token_upload", login.access_token);
        }
        
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
        localStorage.setItem("token_upload", "");
    }

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout }}>
            {children}
        </AuthContext.Provider>
    )
}