import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl, Grid } from "@mui/material";
import BarTop from "../../components/barTop";
import TableDataCategory from "../../components/TableDataCategory";
import { useNavigate } from "react-router-dom";
import { Api } from "../../services/api";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import LogoComponent from "../../components/Logo";

interface newsArray {
  id: number;
  name: string;
  image: string;
  slug?: string;
}

interface OriginalObject {
  id: number;
  title: string;
  image: string;
  position: string;
}

const Banners = () => {
  const token = getUserLocalStorage()?.token;
  const urlModule = { name: "banners", url: "banners" };

  let navigate = useNavigate();
  const addNew = (): void => {
    navigate("/banners/add");
  };
  const [news, setNews] = useState<newsArray[]>([]);

  const displayNews = useCallback(async () => {
    await Api.get(`banners`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }).then((response) => {
      setNews(transformarDados(response.data));
    });
  }, [token]);

  useEffect(() => {
    setNews([]);
    displayNews();
  }, [displayNews]);

  const transformarDados = (dados: OriginalObject[]): newsArray[] => {
    return dados.map((item) => {
      const { id, title, image, position } = item;
      return {
        id,
        name: `${title}`,
        image,
        slug: `${position}`,
      };
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        <BarTop />
        <Grid item p={4}>
          <FormControl sx={{ my: 2, textAlign: "right" }}>
            <Button onClick={() => addNew()} variant="contained">
              Adicionar
            </Button>
          </FormControl>
          <TableDataCategory category={news} nameModule={urlModule} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Banners;
