import * as React from 'react';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import StyleIcon from '@mui/icons-material/Style';
import TocIcon from '@mui/icons-material/Toc';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SegmentIcon from '@mui/icons-material/Segment';
import PersonIcon from '@mui/icons-material/Person';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
      <Grid>
        <MenuItem component={Link} to="/produtos">
          <ListItemIcon>
            <StyleIcon className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Produtos</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/lojas">
          <ListItemIcon>
            <StorefrontIcon  className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Lojas</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/setores">
          <ListItemIcon>
            <SegmentIcon className="branco"  fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Setores</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/categorias">
          <ListItemIcon>
            <TocIcon className="branco"  fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Categorias</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/sub-categorias">
          <ListItemIcon>
            <ClearAllIcon  className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Sub Categorias</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/marcas">
          <ListItemIcon>
            <LocalOfferIcon  className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Marcas</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/usuarios">
          <ListItemIcon>
            <PersonIcon  className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Usuários</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/banners">
          <ListItemIcon>
            <ViewCarouselIcon  className="branco" fontSize="small" />
          </ListItemIcon>
          <ListItemText className='branco'>Banners</ListItemText>
        </MenuItem>
      </Grid>
  );
}