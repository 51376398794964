import React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    Card,
    GridSpacing
} from '@mui/material';
import BarTop from '../components/barTop';
import LogoComponent from '../components/Logo';

const Home = () => {
    return (
        <Grid container>
            <Grid item xs={12} lg={2} sm={12} md={2}>
                <LogoComponent />
            </Grid>
            <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
                <BarTop />
                <Grid item p={4}>
                    <h5>Seja bem-vindo</h5>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;