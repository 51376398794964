import Logo from '../icons/logo';
import Navbar from './navbar';

function LogoComponent() {

  return (
    <div className='menu'>
        <div className="logocenter">
            <Logo w={160} h={60} />
        </div>
        <Navbar />
    </div>
  );
}

export default LogoComponent;
