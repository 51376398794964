import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ModalAvisoProps {
  open: boolean;
  handleClose: (accepted: boolean) => void; // Função de retorno de chamada para lidar com o fechamento do diálogo
}

export default function ModalAviso(props: ModalAvisoProps) {
  const { open, handleClose } = props;

  const handleAccept = () => {
    handleClose(true); // Chama a função de retorno de chamada indicando aceitação
  };

  const handleDecline = () => {
    handleClose(false); // Chama a função de retorno de chamada indicando rejeição
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        keepMounted
        onClose={handleDecline} // Fechar ao clicar fora do diálogo será considerado rejeição
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Tem certeza que desea apagar?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDecline}>Não</Button>
          <Button variant="contained" color="success" onClick={handleAccept}>Sim, desejo apagar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
