import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  LinearProgress,
  Button,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import { useParams } from "react-router-dom";
import { Api, ApiUpload } from "../../services/api";
import { useNavigate } from "react-router-dom";
import MultiUpload from "../../components/multiUploadProduct";
import LogoComponent from "../../components/Logo";

interface ImageUnit {
  id: string;
  image: string;
}

interface sectorsField {
  id: string;
  name: string;
}

interface storeField {
  id: string;
  name: string;
}

const Home = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [load, setLoad] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [codeProduct, setCodeProduct] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [pricePrevius, setPricePrevius] = useState<string>("");
  const [amount, setDiscount] = useState<string>("");
  const [stock, setStock] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [imageUnit, setImageUnit] = useState<ImageUnit[]>([]);
  const [sector, setSector] = useState<string>("");
  const [sectors, setSectors] = useState<sectorsField[]>([]);
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<sectorsField[]>([]);
  const [subcategory, setSubcategory] = useState<string>("");
  const [subcategories, setSubcategories] = useState<sectorsField[]>([]);
  const [store, setStore] = useState<string>("");
  const [stores, setStores] = useState<storeField[]>([]);
  const [description, setDescription] = useState<string>("");
  const [imgfeature, setImgFeature] = useState<string>("");
  const { id } = useParams();

  const token = getUserLocalStorage()?.token;
  const navigate = useNavigate();

  const handleImagesUploaded = (uploadedImages: File[]) => {
    setImages(uploadedImages);
  };

  const handleImagesDeleted = async (deletedImage: any) => {
    console.log(deletedImage);

    const responseUpload = await ApiUpload.delete(
      "uploadfile/delete/" + deletedImage.upload_id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
        },
      }
    );

    if (responseUpload) {
      await Api.delete("productsImages/" + deletedImage.id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newItem = imageUnit.filter((item) => item.id !== deletedImage.id);
      setImageUnit(newItem);
    }
  };

  const handleImagesMark = async (image: string) => {
    await Api.put(
      "products/" + id,
      {
        image: image,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setImgFeature(image);
  };

  const displayPreview = useCallback(async () => {
    try {
      const allSectors = await Api.get(`sector/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      setSectors(allSectors.data);
      const allStores = await Api.get(`store/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      setStores(allStores.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, []);

  useEffect(() => {
    displayPreview();
    if (id !== "add") {
      const displayProducts = async () => {
        try {
          const response = await Api.get(`products/` + id, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          });

          if (response.data) {
            const allCategories = await Api.get(
              `/category/sectorbycategory/` + response.data.sectorId,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  Expires: "0",
                },
              }
            );
            setCategories(allCategories.data);
            const allSubcategories = await Api.get(
              `/subcategory/sectorbysubcategory/` + response.data.categoryId,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  Expires: "0",
                },
              }
            );
            setSubcategories(allSubcategories.data);
            const allImages = await Api.get(
              `/productsImages/imageproduct/` + response.data.id,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  Expires: "0",
                },
              }
            );
            setImageUnit(allImages.data);
          }

          setTitle(response.data?.title);
          setPrice(response.data?.price);
          setPricePrevius(response.data?.price_before);
          setDiscount(response.data?.discount);
          setStock(response.data?.quantity);
          setCodeProduct(response.data?.codeProduct);
          setImgFeature(response.data?.image);
          const blocksFromHTML = convertFromHTML(response.data?.description);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          const newEditorState = EditorState.createWithContent(state);
          setEditorState(newEditorState);
          setSector(String(response.data?.sectorId));
          setCategory(String(response.data?.categoryId));
          setSubcategory(String(response.data?.subcategoryId));
          setStore(response.data?.storeId);
          setChecked(response.data?.feature === 1);
          setDescription(response.data?.description);
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
      };
      displayProducts();
    }
  }, [id, displayPreview, token]);

  const handleSave = async () => {
    setLoad(true);
    if (id === "add") {
      handleCreate();
    } else {
      handleEdit(id);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await Api.post(
        "products",
        {
          title: title,
          feature: checked,
          codeProduct: codeProduct,
          price: price,
          price_before: pricePrevius,
          discount: amount,
          quantity: stock,
          store_id: store,
          sector_id: sector,
          category_id: category,
          subcategory_id: subcategory,
          description: description,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (images.length) {
        images.forEach(async (image) => {
          const productsMulti = await Api.post(
            "/productsImages/",
            {
              products_id: response.data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const formData = new FormData();
          formData.append("table", "products");
          formData.append("table_id", productsMulti.data.id);
          formData.append(`image`, image);
          const responseUpload = await ApiUpload.post(
            "uploadsinglemulti",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token_upload")}`,
              },
            }
          );

          await Api.put(
            "/productsImages/" + productsMulti.data.id,
            {
              image: responseUpload.data.path,
              upload_id: responseUpload.data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        });
      }

      navigate("/produtos/" + response.data.id);
      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleEdit = async (idProduct: string | any) => {
    try {
      await Api.put(
        "products/" + idProduct,
        {
          title: title,
          feature: checked,
          codeProduct: codeProduct,
          price: price,
          price_before: pricePrevius,
          discount: amount,
          storeId: store,
          quantity: stock,
          sectorId: sector,
          categoryId: category,
          subcategoryId: subcategory,
          description: description,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (images.length) {
        try {
          const uploadPromises = images.map(async (image) => {
            const productsMulti = await Api.post(
              "/productsImages/",
              {
                products_id: idProduct,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const formData = new FormData();
            formData.append("table", "products");
            formData.append("table_id", productsMulti.data.id);
            formData.append("image", image);

            const responseUpload = await ApiUpload.post(
              "uploadsinglemulti",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "token_upload"
                  )}`,
                },
              }
            );

            await Api.put(
              `/productsImages/${productsMulti.data.id}`,
              {
                image: responseUpload.data.path,
                upload_id: responseUpload.data.id,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
          });

          await Promise.all(uploadPromises);
          console.log("Todas as imagens foram cadastradas com sucesso!");
        } catch (error) {
          console.error("Erro ao cadastrar imagens:", error);
        }
      }

      setLoad(false);
    } catch (error) {
      console.error("Erro na solicitação:", error);
      setLoad(false);
    }
  };

  const handleChangeSelect = async (event: SelectChangeEvent) => {
    setSector(event.target.value);
    const allCategories = await Api.get(
      `/category/sectorbycategory/` + event.target.value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    setCategories(allCategories.data);
  };

  const handleChangeCategory = async (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    const allSubcategories = await Api.get(
      `/subcategory/sectorbysubcategory/` + event.target.value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    setSubcategories(allSubcategories.data);
  };

  const handleChangeSubcategory = (event: SelectChangeEvent) => {
    setSubcategory(event.target.value);
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCodeProduct(event.target.value);
  };

  const handleChangPrice = (event: ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handleChangePricePrevius = (event: ChangeEvent<HTMLInputElement>) => {
    setPricePrevius(event.target.value);
  };

  const handleChangeDiscount = (event: ChangeEvent<HTMLInputElement>) => {
    setDiscount(event.target.value);
  };

  const handleChangeStock = (event: ChangeEvent<HTMLInputElement>) => {
    setStock(event.target.value);
  };

  const handleChangeStatus = () => {
    setChecked(!checked);
  };

  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState: ContentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    const contentStateFromRaw = convertFromRaw(rawContentState);
    const blocksHTML = stateToHTML(contentStateFromRaw);

    setDescription(blocksHTML);
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        {load && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ p: 4, m: 4, background: "#ffffff" }}>
          <Grid container>
            <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl variant="standard" sx={{ width: "96%", m: 2 }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Setor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sector}
                  label="Setor"
                  onChange={handleChangeSelect}
                >
                  {sectors.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl variant="standard" sx={{ width: "96%", m: 2 }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Categoria
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Categoria"
                  onChange={handleChangeCategory}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} sm={4} md={4}>
              <FormControl variant="standard" sx={{ width: "96%", m: 2 }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Sub categoria
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subcategory}
                  label="Sub categoria"
                  onChange={handleChangeSubcategory}
                >
                  {subcategories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Título"
                  variant="standard"
                  value={title}
                  onChange={handleChangeTitle}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl variant="standard" sx={{ width: "96%", m: 4 }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Store
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={store}
                  label="Loja"
                  onChange={handleChangeStore}
                >
                  {stores.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  sx={{ m: 2 }}
                  id="standard-basic"
                  label="Código produto"
                  variant="standard"
                  value={codeProduct}
                  onChange={handleChangeCode}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ m: 2 }}
                    checked={checked}
                    onChange={handleChangeStatus}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Destaque"
              />
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  id="standard-basic"
                  label="Preço"
                  variant="standard"
                  value={price}
                  onChange={handleChangPrice}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  id="standard-basic"
                  label="Preço anterior"
                  variant="standard"
                  value={pricePrevius}
                  onChange={handleChangePricePrevius}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  id="standard-basic"
                  label="Estoque"
                  variant="standard"
                  value={stock}
                  onChange={handleChangeStock}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <FormControl sx={{ width: "96%", m: 2 }}>
                <TextField
                  id="standard-basic"
                  label="Desconto"
                  variant="standard"
                  value={amount}
                  onChange={handleChangeDiscount}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sm={6} md={6}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  sx={{ m: 2 }}
                  id="standard-basic"
                  label="Tag"
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <FormControl sx={{ my: 2, width: "100%" }}>
                  <MultiUpload
                    limit={8}
                    onImagesUploaded={handleImagesUploaded}
                    onImagesDeleted={handleImagesDeleted}
                    onImagesMark={handleImagesMark}
                    imagePreview={imageUnit}
                    imageFeature={imgfeature}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ my: 2 }}>
            <Button onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
