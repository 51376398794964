import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Card,
  Alert,
  Snackbar,
  Skeleton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../context/AuthProvider/useAuth";

const Login = () => {
  const [alertLogin, setAlertLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const auth = useAuth();
  
  let navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any): void => {
    event.preventDefault();
  };

  const handleCloseAlert = () => {
    setAlertLogin(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      display={"inline-block"}
      sx={{ minHeight: "100vh", backgroundColor: "#07115c" }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <Card sx={{ minWidth: 275, padding: "20px" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">
                      Entrar na área restrita
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Por favor, digite um e-mail válido!")
                    .max(255)
                    .required("Ingrese seu email"),
                  password: Yup.string().max(255).required("Digite sua senha"),
                })}
                onSubmit={async (
                  values
                ) => {
                  try {
                    setloading(true);
                    await auth.authenticate(values.email, values.password);
                    navigate("/home");
                    setloading(false);
                  } catch (error) {
                    setAlertLogin(true);
                    setloading(false);
                    values.password = "";
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    {!loading ?
                      <>
                      <Box my={2}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                        >
                          <InputLabel htmlFor="outlined-adornment-email-login">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-email-login"
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Email"
                            inputProps={{}}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                            >
                              {errors.email}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                    
                        <Box my={2}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                          >
                            <InputLabel htmlFor="outlined-adornment-password-login">
                              Senha
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password-login"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                              inputProps={{}}
                            />
                            {touched.password && errors.password && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-password-login"
                              >
                                {errors.password}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                          <Button
                            disableElevation
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            ACESSAR
                          </Button>
                        </Box>
                      </>
                    :
                      <>
                        <Skeleton variant="rectangular" width={275} height={56} />
                        <Skeleton variant="rectangular" sx={{mt:2}} width={275} height={56} />
                      </>
                    }
                  </form>
                )}
              </Formik>
              { alertLogin &&
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={true}
                  autoHideDuration={6000}
                  onClose={handleCloseAlert}
                  key={ "top" + "right"}>
                  <Alert severity="error">Login ou senha errados, tente novamente por favor!</Alert>
                </Snackbar>
              }
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
