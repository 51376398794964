import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl, Grid } from "@mui/material";
import BarTop from "../../components/barTop";
import TableData from "../../components/TableData";
import { useNavigate } from "react-router-dom";
import { Api } from "../../services/api";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import LogoComponent from "../../components/Logo";

interface newsArray {
  id: number;
  title: string;
  image: string;
  date_create: Date;
}

const Product = () => {
  const token = getUserLocalStorage()?.token;
  const urlModule = { name: "products", url: "produtos" };
  const limit = 10;

  let navigate = useNavigate();
  const addNew = (): void => {
    navigate("/produtos/add");
  };
  const [news, setNews] = useState<newsArray[]>([]);

  useEffect(() => {
    setNews([]);
    const displayNews = async () => {
      await Api.get(`products`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then((response) => {
        setNews(response.data);
      });
    };
    displayNews();
  }, [token]);

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        <BarTop />
        <Grid item p={4}>
          <FormControl sx={{ my: 2, textAlign: "right" }}>
            <Button onClick={() => addNew()} variant="contained">
              Adicionar
            </Button>
          </FormControl>
          <TableData products={news} nameModule={urlModule} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Product;
