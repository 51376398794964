import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import LogoComponent from "../../components/Logo";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ImageUploader from "../../components/multiUpload";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import { useParams } from 'react-router-dom';
import { Api, ApiUpload } from "../../services/api";
import { useNavigate } from "react-router-dom";

interface ImageUnit {
  id: string;
  image: string;
}

interface categoriesField {
  id: string;
  name: string;
}

const SectorEdit: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [categoryItem, setCategory] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [load, setLoad] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [categories, setCategories] = useState<categoriesField[]>([]);
  const [imageUnit, setImageUnit] = useState<ImageUnit[]>([]);
  const { id } = useParams();

  const token = getUserLocalStorage()?.token;
  const navigate = useNavigate();

  const handleImagesUploaded = (uploadedImages: File[]) => {
    setImages(uploadedImages);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleImagesDeleted = async (deletedImage: string) => {
    const responseUpload = await ApiUpload.delete('deletesingle/subcategory/'+deletedImage, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token_upload')}` },
    });

    if (responseUpload) {
      await Api.put('subcategory/' + deletedImage, {
        image: null,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setImageUnit([]);
    }
  };

  const displayPreview = useCallback(async () => {
    const allCategories = await Api.get(`category/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setCategories(allCategories.data);
  }, []);

  const displayCategory = useCallback(async () => {
    try {
      const response = await Api.get(`subcategory/` + id, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTitle(response.data?.name);
      setCategory(String(response.data?.categoryId));

      if (response.data?.image && id) {
        const pic = 'https://upload.megaroga.dev.br/storage/' + response.data?.image;
        setImageUnit([...imageUnit, {id: id, image: pic}]);
      }
      setChecked(response.data?.status === 1);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, [id, token]);

  useEffect(() => {
    displayPreview();
    if (id !== 'add') {
      setTitle("");
      displayCategory();
    }
  }, [id, displayPreview, displayCategory]);
  
  const handleChangeStatus = () => {
    setChecked(!checked);
  };

  const handleSave = async () => {
    setLoad(true);
    if (id === 'add') {
      handleCreate();
    } else {
      handleEdit(id);
    }
  }

  const handleCreate = async () => {
    try {
      const response = await Api.post('subcategory', {
        name: title,
        category_id: categoryItem,
        status: checked,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      if (images[0]) {
        const formData = new FormData();
        formData.append('image', images[0]);
        formData.append('table', 'subcategory');
        formData.append('table_id', response.data.id);

        const responseUpload = await ApiUpload.post('uploadsingle', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token_upload')}` },
        });

        await Api.put('subcategory/' + response.data.id, {
          image: responseUpload.data.path,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      navigate('/sub-categorias/'+response.data.id);
      setLoad(false);
    } catch (error) {
      console.error('Erro na solicitação:', error);
      setLoad(false);
    };
  };

  const handleEdit = async (idSector:string|any) => {
    try {
      await Api.put('subcategory/' + idSector, {
        name: title,
        category_id: categoryItem,
        status: checked,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (images[0]) {
        const formData = new FormData();
        formData.append('image', images[0]);
        formData.append('table', 'subcategory');
        formData.append('table_id', idSector);
        
        const responseUpload = await ApiUpload.post('uploadsingle', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token_upload')}` },
        });
        
        await Api.put('subcategory/' + idSector, {
          image: responseUpload.data.path,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setLoad(false);
    } catch (error) {
      console.error('Erro na solicitação:', error);
      setLoad(false);
    }
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        {
          load &&
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        }
        <Box sx={{ p: 4, m: 4, background: "#ffffff" }}>
          <Grid container>
            <Grid item xs={12} lg={8} sm={8} md={8}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  sx={{ my: 2 }}
                  id="standard-basic"
                  label="Título"
                  variant="standard"
                  value={title}
                  onChange={handleChangeTitle}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8} sm={8} md={8}>
              <FormControl variant="standard" sx={{ minWidth: 320, py:2 }}>
                <InputLabel id="demo-simple-select-filled-label">Categoria</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryItem}
                  label="Setor"
                  onChange={handleChangeSelect}
                  >
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8} sm={8} md={8}>
              <FormControlLabel control={
                <Switch
                  checked={checked}
                  onChange={handleChangeStatus}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label="Status" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <FormControl sx={{ my:2, width: "100%" }}>
                <ImageUploader limit={1} onImagesUploaded={handleImagesUploaded} onImagesDeleted={handleImagesDeleted} imagePreview={imageUnit}/>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ my:2 }}>
            <Button onClick={handleSave} variant="contained">Salvar</Button>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SectorEdit;
