import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl, Grid } from "@mui/material";
import BarTop from "../../components/barTop";
import TableDataCategory from "../../components/TableDataCategory";
import { useNavigate } from "react-router-dom";
import { Api } from "../../services/api";
import { getUserLocalStorage } from "../../context/AuthProvider/util";
import LogoComponent from "../../components/Logo";

interface userArray {
  id: number;
  name: string;
  image: string;
  slug?: string;
}

interface OriginalObject {
  id: number;
  lastName: string;
  firstName: string;
  groupId: number;
  image: string;
}

const Category = () => {
  const token = getUserLocalStorage()?.token;
  const urlModule = { name: "users", url: "usuarios" };

  let navigate = useNavigate();
  const addUser = (): void => {
    navigate("/usuarios/add");
  };
  const [user, setUsers] = useState<userArray[]>([]);

  const displayUsers = useCallback(async () => {
    await Api.get(`users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }).then((response) => {
      setUsers(transformarDados(response.data));
    });
  }, [token]);

  useEffect(() => {
    setUsers([]);
    displayUsers();
  }, [displayUsers]);

  const transformarDados = (dados: OriginalObject[]): userArray[] => {
    return dados.map((item) => {
      const { id, lastName, firstName, image } = item;
      return {
        id,
        name: `${lastName} ${firstName}`,
        image,
      };
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={2} sm={12} md={2}>
        <LogoComponent />
      </Grid>
      <Grid item xs={12} lg={10} sm={12} md={10} sx={{ pl: "0 !important" }}>
        <BarTop />
        <Grid item p={4}>
          <FormControl sx={{ my: 2, textAlign: "right" }}>
            <Button onClick={() => addUser()} variant="contained">
              Adicionar
            </Button>
          </FormControl>
          <TableDataCategory category={user} nameModule={urlModule} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Category;
