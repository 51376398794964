import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Home from "./pages/Home";
import PageProducts from "./pages/PageProducts";
import PageProductsEdit from "./pages/PageProducts/edit";
import PageSector from "./pages/PageSector";
import PageSectorEdit from "./pages/PageSector/edit";
import PageCategory from "./pages/PageCategory";
import PageCategoryEdit from "./pages/PageCategory/edit";
import PageSubCategory from "./pages/PageSubCategory";
import PageSubCategoryEdit from "./pages/PageSubCategory/edit";
import PageBrands from "./pages/PageBrands";
import PageBrandsEdit from "./pages/PageBrands/edit";
import PageUsers from "./pages/PageUsers";
import PageUsersEdit from "./pages/PageUsers/edit";
import PageStore from "./pages/PageStore";
import PageStoreEdit from "./pages/PageStore/edit";
import PageBanners from "./pages/PageBanners";
import PageBannersEdit from "./pages/PageBanners/edit";
import { ProtectedLayout } from "./components/protectedLayout";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={
        <ProtectedLayout>
          <Home />
        </ProtectedLayout>
      } />
      <Route path="/produtos" element={
        <ProtectedLayout>
          <PageProducts />
        </ProtectedLayout>
      } />
      <Route path="/produtos/:id" element={
        <ProtectedLayout>
          <PageProductsEdit />
        </ProtectedLayout>
      } />
      <Route path="/setores" element={
        <ProtectedLayout>
          <PageSector />
        </ProtectedLayout>
      } />
      <Route path="/setores/:id" element={
        <ProtectedLayout>
          <PageSectorEdit />
        </ProtectedLayout>
      } />
      <Route path="/categorias" element={
        <ProtectedLayout>
          <PageCategory />
        </ProtectedLayout>
      } />
      <Route path="/categorias/:id" element={
        <ProtectedLayout>
          <PageCategoryEdit />
        </ProtectedLayout>
      } />
      <Route path="/sub-categorias" element={
        <ProtectedLayout>
          <PageSubCategory />
        </ProtectedLayout>
      } />
      <Route path="/sub-categorias/:id" element={
        <ProtectedLayout>
          <PageSubCategoryEdit />
        </ProtectedLayout>
      } />
      <Route path="/marcas" element={
        <ProtectedLayout>
          <PageBrands />
        </ProtectedLayout>
      } />
      <Route path="/marcas/:id" element={
        <ProtectedLayout>
          <PageBrandsEdit />
        </ProtectedLayout>
      } />
      <Route path="/usuarios" element={
        <ProtectedLayout>
          <PageUsers />
        </ProtectedLayout>
      } />
      <Route path="/usuarios/:id" element={
        <ProtectedLayout>
          <PageUsersEdit />
        </ProtectedLayout>
      } />
      <Route path="/lojas" element={
        <ProtectedLayout>
          <PageStore />
        </ProtectedLayout>
      } />
      <Route path="/lojas/:id" element={
        <ProtectedLayout>
          <PageStoreEdit />
        </ProtectedLayout>
      } />
      <Route path="/banners" element={
        <ProtectedLayout>
          <PageBanners />
        </ProtectedLayout>
      } />
      <Route path="/banners/:id" element={
        <ProtectedLayout>
          <PageBannersEdit />
        </ProtectedLayout>
      } />
    </Routes>
  );
};

export default MainRoutes;
