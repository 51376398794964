import * as React from "react"
import { SVGProps } from "react"

interface SVGSVGElement {
  w: number,
  h: number
}

const Logo = (props: SVGSVGElement) => (
  <img src='../logo.png' width={props.w} height={props.h} className='imglogo'/>
)

export default Logo;